<template>
  <div>
    <h1 class="mb-4 mt-4">User Listing</h1>
    <v-row class="mt-4" style="width: 100%">
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="filter.type"
          :items="[{ label: 'All', value: '' }, { label: 'Free', value: 'free' }, { label: 'Paid', value: 'paid' }]"
          item-text="label"
          item-value="value"
          menu-props="offsetY"
          solo
          dense
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Type:</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="4" md="3" class="sm-margin-top-25px">
        <v-select
          v-model="filter.status"
          :items="[{ label: 'All', value: '' }, { label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }]"
          item-text="label"
          item-value="value"
          menu-props="offsetY"
          solo
          dense
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Status:</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="4" md="3" class="sm-margin-top-25px">
        <v-text-field
          v-model="filter.email"
          dense
          type="email"
          solo
          clearable
          @input="changeFilter('email', $event)"
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Email:</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" class="sm-margin-top-25px">
        <div>
          <v-btn
            style="height: 38px"
            color="primary"
            class="text-capitalize confirm-btn"
            block
            @click="getData"
          >
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row style="margin-top: -20px;">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          item-key="id"
          :server-items-length="pagination.total"
          :options="{
             page: pagination.currentPage,
             itemsPerPage: pagination.take
          }"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100]
          }"
          @update:options="getData"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ capitalizeFirstLetter(item.type) }}
          </template>
          <template v-slot:[`item.number`]="{ item }">
            {{ item.number || '-' }}
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <v-chip
              :class="{
                'success-lighten': item.is_active,
                'red-lighten': !item.is_active
              }"
              small
              class="text-capitalize"
            >
              <span v-if="item.is_active">Active</span>
              <span v-else>Inactive</span>
            </v-chip>
          </template>
          <template v-slot:[`item.deactivated_on`]="{ item }">
            {{ formatDate(item.deactivated_on) || '-' }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div style="min-width:310px">
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleStatus(item)"
                  >
                    <v-icon color="#919EAB">{{ item.is_active ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.is_active ? 'Deactivate' : 'Activate' }}</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="openUpdateUserDialog(item)"
                  >
                    <v-icon color="#919EAB">mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip v-if="!isWhitelabel" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="goTo('AdminPayment', item)"
                  >
                    <v-icon color="#919EAB">mdi-credit-card-outline</v-icon>
                  </v-btn>
                </template>
                <span>View Payment</span>
              </v-tooltip>
              <v-tooltip v-if="!isWhitelabel" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="$router.push({ name: 'AdminInvoice', query: { user: item.uuid } })"
                  >
                    <v-icon color="#919EAB">mdi-receipt-text</v-icon>
                  </v-btn>
                </template>
                <span>View Invoice</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="goTo('AdminLogonHistory', item)"
                  >
                    <v-icon color="#919EAB">mdi-login</v-icon>
                  </v-btn>
                </template>
                <span>Login History</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="goTo('AdminNumber', item)"
                  >
                    <v-icon color="#919EAB">mdi-cellphone-text</v-icon>
                  </v-btn>
                </template>
                <span>Number History</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="goTo('AdminCallHistory', item)"
                  >
                    <v-icon color="#919EAB">mdi-phone-log</v-icon>
                  </v-btn>
                </template>
                <span>Call History</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="goTo('AdminSmsHistory', item)"
                  >
                    <v-icon color="#919EAB">mdi-message-bulleted</v-icon>
                  </v-btn>
                </template>
                <span>SMS History</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top" v-if="!isWhitelabel && item.type !== 'paid'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="openModalUpgradePlan(item)"
                  >
                    <v-img
                      :src="require('../../assets/svg/upgrade-inactive.svg')"
                      width="20"
                      height="20"
                      contain
                    />
                  </v-btn>
                </template>
                <span>Upgrade Plan</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top" v-if="item.type !== 'free'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="rotate: 180deg"
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="openModalDowngradePlan(item)"
                  >
                    <v-img
                      :src="require('../../assets/svg/upgrade-inactive.svg')"
                      width="20"
                      height="20"
                      contain
                    />
                  </v-btn>
                </template>
                <span>Downgrade Plan</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Upgrade plan dialog -->
    <v-dialog v-model="upgradeDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Upgrade plan
          <v-spacer></v-spacer>
          <v-btn @click="closeModalUpgradeDowngrade" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-select
            v-model="upgradePayload.plan_uuid"
            :items="this.planList.filter(item => !item.plan_name.toLowerCase().includes('free'))"
            item-text="plan_name"
            item-value="plan_uuid"
            menu-props="offsetY"
            solo
            dense
            outlined
          >
            <template v-slot:append>
              <v-icon size="15px">mdi-chevron-down</v-icon>
            </template>
          </v-select>

          <div class="upgrade-plan-dialog-row">
            <v-select
              v-model="upgradePayload.duration"
              :items="durationOptions"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              solo
              dense
              outlined
              label="Duration"
            >
              <template v-slot:append>
                <v-icon size="15px">mdi-chevron-down</v-icon>
              </template>
            </v-select>

            <v-select
              v-model="upgradePayload.duration_unit"
              :items="durationUnits"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              solo
              dense
              outlined
            >
              <template v-slot:append>
                <v-icon size="15px">mdi-chevron-down</v-icon>
              </template>
            </v-select>
          </div>

<!--          <div class="d-flex justify-end w-100 mt-2 text&#45;&#45;black font-weight-bold text-h6" v-if="upgradePayload.duration >= 1">-->
<!--            <div class="mr-2">Total:</div>-->
<!--            <div>${{ totalPlanPrice }}</div>-->
<!--          </div>-->
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="closeModalUpgradeDowngrade"
          >Cancel
          </v-btn>
          <v-btn
            :disabled="upgradePayload.duration < 1"
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            :loading="loadingUpgradePlanDialog"
            @click="upgradeTariffPlan"
          >Upgrade
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Downgrade plan dialog -->
    <v-dialog v-model="downgradePlanDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Downgrade plan
          <v-spacer></v-spacer>
          <v-btn @click="closeModalUpgradeDowngrade" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          Are you want, to downgrade tariff plan?
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="closeModalUpgradeDowngrade"
          >Cancel
          </v-btn>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            @click="downgradeTariffPlan"
          >Downgrade
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Update user -->
    <v-dialog v-model="updateUser.show" width="500">
      <v-card>
        <v-card-title class="body-1">
          Update User
          <v-spacer></v-spacer>
          <v-btn @click="closeUpdateUserDialog" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-checkbox
            v-model="updateUser.data.enableVoice"
            color="primary"
            class="mt-0"
            label="Enable Voice"
          ></v-checkbox>
          <v-checkbox
            v-model="updateUser.data.enableText"
            color="primary"
            class="mt-0"
            label="Enable Text"
          ></v-checkbox>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="closeUpdateUserDialog"
          >Cancel
          </v-btn>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            @click="toggleVoiceText"
          >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import removeObjectEmptyValues from '../../utilities/removeObjectEmptyValues'
import { capitalizeFirstLetter } from '../../utilities/formatter'
import { isWhitelabel } from '@/utilities/api'

export default {
  name: 'Users',
  data: () => ({
    isWhitelabel,
    loading: false,
    pagination: {
      take: 10,
      skip: 0,
      total: 0,
      currentPage: 1
    },
    filter: {
      orderType: 'DESC',
      orderBy: 'created_at',
      type: '',
      status: '',
      email: ''
    },
    headers: [
      {
        text: 'Registered On',
        align: 'start',
        sortable: false,
        value: 'created_at',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Email',
        align: 'start',
        sortable: false,
        value: 'email',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Type',
        align: 'start',
        sortable: false,
        value: 'type',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Number',
        align: 'start',
        sortable: false,
        value: 'number',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'is_active',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Deactivated On',
        align: 'start',
        sortable: false,
        value: 'deactivated_on',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ],
    items: [],
    planList: [],
    durationUnits: [
      { label: 'Month', value: 'month' },
      { label: 'Year', value: 'year' }
    ],
    timeoutTyping: null,
    timeoutTypingIndex: null,
    upgradeDialog: false,
    upgradePayload: {
      user_uuid: '',
      plan_uuid: '',
      duration_unit: 'month',
      duration: 1
    },
    updateUser: {
      show: false,
      uuid: '',
      data: {
        enableVoice: false,
        enableText: false
      }
    },
    downgradePlanDialog: false,
    loadingUpgradePlanDialog: false
  }),
  created () {
    this.loadPlans()
    // this.getData()
  },
  beforeDestroy() {
    clearTimeout(this.timeoutTypingIndex)
  },
  computed: {
    durationOptions () {
      return Array.from({ length: this.upgradePayload.duration_unit === 'month' ? 11 : 10 }, (value, index) => ({ label: index + 1, value: index + 1 }))
    }
  },
  methods: {
    ...mapActions('admin', ['getAdminUsers', 'deactivateAdminUser', 'upgradeDowngradePlanUser', 'adminUpdateUserProfile']),
    ...mapActions(['showAlert', 'getPlanList']),
    capitalizeFirstLetter,
    closeModalUpgradeDowngrade() {
      this.upgradeDialog = false
      this.downgradePlanDialog = false
      this.upgradePayload.user_uuid = null
      this.upgradePayload.plan_uuid = null
      this.upgradePayload.duration_unit = 'month'
      this.upgradePayload.duration = 1
    },
    openModalUpgradePlan(item) {
      this.upgradePayload.user_uuid = item.uuid
      this.upgradeDialog = true
    },
    openModalDowngradePlan(item) {
      this.upgradePayload.user_uuid = item.uuid
      this.downgradePlanDialog = true
    },
    async loadPlans() {
      try {
        this.loadingUpgradePlan = true
        const data = await this.getPlanList()
        if (!data || !data.data || !data.data.length) return
        this.planList = data.data
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loadingUpgradePlan = false
      }
    },
    async upgradeTariffPlan () {
      try {
        this.loadingUpgradePlanDialog = true
        const payload = {
          user_uuid: this.upgradePayload.user_uuid,
          plan_uuid: this.upgradePayload.plan_uuid,
          duration_unit: this.upgradePayload.duration_unit,
          duration: this.upgradePayload.duration
        }

        await this.upgradeDowngradePlanUser(payload)
        this.showAlert({ message: 'Plan updated successfully.' })
        this.getData()
        this.closeModalUpgradeDowngrade()
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.loadingUpgradePlanDialog = false
        this.upgradeDialog = false
      }
    },
    async downgradeTariffPlan () {
      try {
        this.downgradePlanDialog = true

        const payload = {
          user_uuid: this.upgradePayload.user_uuid
        }

        await this.upgradeDowngradePlanUser(payload)
        this.showAlert({ message: 'Tariff plan was downgraded' })
        this.getData()
        this.closeModalUpgradeDowngrade()
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.downgradePlanDialog = false
      }
    },
    goTo (name, item) {
      this.$router.push({ name, query: { user: item.uuid, email: btoa(item.email) } })
    },
    preventOverloading () {
      if (this.timeoutTyping) return
      this.timeoutTyping = true
      this.timeoutTypingIndex = setTimeout(() => {
        this.getData()
        clearTimeout(this.timeoutTypingIndex)
        this.timeoutTyping = null
      }, 600)
    },
    async getData (params = {}) {
      try {
        this.loading = true
        if (Object.keys(params).length > 0 && params.page && params.itemsPerPage) {
          this.pagination.take = params.itemsPerPage
          this.pagination.skip = (params.page - 1) * params.itemsPerPage
        }
        const payload = {
          ...removeObjectEmptyValues(this.filter),
          take: this.pagination.take,
          skip: this.pagination.skip
        }
        const res = await this.getAdminUsers(payload)
        this.pagination.total = res.total
        this.items = res.data
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loading = false
      }
    },
    async changeFilter (type, $event) {
      this.filter[type] = $event ? $event.toString() : ''
      if (type !== 'email' || (type === 'email' && !$event)) {
        await this.getData()
        return
      }
      this.preventOverloading()
    },
    async toggleStatus (item) {
      try {
        this.loading = true
        await this.deactivateAdminUser({ enable: item.is_active, id: item.uuid })
        await this.getData()
        this.showAlert({ message: 'User status updated' })
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        this.getData()
      } finally {
        this.loading = false
      }
    },
    openUpdateUserDialog(item) {
      this.updateUser = {
        show: true,
        uuid: item.uuid,
        data: {
          enableVoice: item.enableVoice !== undefined ? item.enableVoice : false,
          enableText: item.enableText !== undefined ? item.enableText : false
        }
      }
    },
    closeUpdateUserDialog () {
      this.updateUser = {
        show: false,
        uuid: '',
        data: {
          enableVoice: false,
          enableText: false
        }
      }
    },
    async toggleVoiceText () {
      try {
        this.loading = true
        await this.adminUpdateUserProfile(this.updateUser)
        await this.getData()
        this.showAlert({ message: 'User updated' })
        this.closeUpdateUserDialog()
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        this.getData()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.mt-2px {
  margin-top: 2px;
}
@media (max-width: 599px) {
  .sm-margin-top-25px {
    margin-top: -25px!important;
  }
}
</style>
