<template>
  <v-col cols="12">
    <p>Calls</p>
    <v-card elevation="0" outlined>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Outgoing calls
            </v-list-item-title>
            <v-list-item-subtitle class="caption"
              >You can use {{ profileData && profileData.company_domain ? profileData.company_domain : 'FreeFone' }} on the web to place calls on your phone
              instead of over the internet. Carrier rates may apply.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>

      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Anonymous caller ID
            </v-list-item-title>
            <v-list-item-subtitle class="caption"
              >Hide your caller ID on outgoing calls
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-switch
            :value="profile.anon_caller_id"
            @change="hideAnonymousIdHandler"
          ></v-switch>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-card-text>
        <div class="dark--text font-weight-medium fz-16">Incoming calls</div>
        <p class="caption">
          Select the devices you want to use to answer incoming {{ profileData && profileData.company_domain ? profileData.company_domain : 'FreeFone' }} calls
          and any numbers you want to forward your calls to. Carrier rates may
          apply
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout align-center>
          <div class="icon-wrapper">
            <v-icon small color="primary">mdi-monitor</v-icon>
          </div>
          <h3 class="ms-3 font-weight-normal">Web</h3>
          <v-switch
            @change="(val) => profileUpdate('enable_web_device', val)"
            v-model="profile.enable_web_device"
            :input-value="profile.enable_web_device"
            class="ms-auto"
          >
          </v-switch>
        </v-layout>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <h4 class="dark--text  font-weight-medium fz-16">Call forwarding</h4>
        <!--        <h4 class="dark&#45;&#45;text">{{ profile.call_forward_to ? profile.call_forward_to : 'Call forwarding'}}</h4>-->
        <p class="caption">
          Turn on the linked numbers you want to forward calls to
        </p>
        <v-layout v-if="!linkedNumbers.length" class="my-8">
          <v-btn
            @click="$emit('openLinkedNumberDialog')"
            :block="$vuetify.breakpoint.xs"
            color="primary"
            elevation="0"
            outlined
          >
            <v-icon left color="primary">mdi-plus</v-icon>
            Add Linked Number
          </v-btn>
        </v-layout>
        <template v-else>
          <v-layout
            v-for="(number, index) in linkedNumbers"
            :key="index + 'linked'"
            align-center
            class="my-8"
          >
            <div class="icon-wrapper">
              <v-icon small color="primary">mdi-cellphone</v-icon>
            </div>

            <div class="w-100 d-flex justify-space-between flex-row flex-wrap">
              <h4 class="dark--text d-inline-block mt-2 ml-3">
                {{ number.linked_number || 0 }}
              </h4>

              <div>
                <v-btn
                  color="primary"
                  elevation="0"
                  :outlined="$vuetify.breakpoint.smAndUp"
                  :block="$vuetify.breakpoint.xs"
                  class="font-weight-bold"
                  @click="profile.call_forward_to === number.linked_number ? deactivateCallForwording(number.uuid) : profileUpdate('call_forward_to', number.linked_number)"
                >
                  {{ profile.call_forward_to === number.linked_number ? 'Deactivate' : 'Activate' }}
                </v-btn>
              </div>
            </div>
          </v-layout>
        </template>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <h4 class="dark--text font-weight-medium fz-16">Custom call forwarding</h4>
        <p class="mb-0 caption">
          Choose how to handle calls from specific callers.
          <!-- <v-btn
            height="0"
            class="caption pa-0 text-capitalize"
            :ripple="false"
            plain
            text
            color="info"
            >Learn more
          </v-btn> -->
        </p>
      </v-card-text>
      <v-card-actions class="pa-4 d-block sm-d-flex">
        <v-btn
          color="primary"
          elevation="0"
          :outlined="$vuetify.breakpoint.smAndUp"
          :block="$vuetify.breakpoint.xs"
          class="font-weight-medium"
          @click="$router.push({ name: 'AddRule' })"
        >
          <v-icon left :color="$vuetify.breakpoint.xs ? 'white' : 'primary'"
            >mdi-plus</v-icon
          >
          Create a rule
        </v-btn>
        <v-btn
          v-if="ruleCount"
          color="primary"
          elevation="0"
          class="ms-0 ms-sm-3 pa-0 pa-sm-4 mt-3 mt-sm-0 font-weight-medium"
          :block="$vuetify.breakpoint.xs"
          :outlined="$vuetify.breakpoint.smAndUp"
          @click="$router.push({ name: 'ManageRule' })"
          >Manage rules
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Get email alerts for missed calls
            </v-list-item-title>
            <!-- <v-list-item-subtitle class="caption">
              {{ profile.enable_miss_call_email }}
            </v-list-item-subtitle> -->
          </v-list-item-content>
          <v-switch
            v-model="profile.enable_miss_call_email"
            :input-value="profile.enable_miss_call_email"
            @change="(val) => profileUpdate('enable_miss_call_email', val, 'Get email alerts for missed calls')"
          ></v-switch>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Screen calls
            </v-list-item-title>
            <v-list-item-subtitle class="caption"
              >Hear a caller's name when you pick up
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-switch
            v-model="profile.enable_screen_call"
            :input-value="profile.enable_screen_call"
            @change="toggleScreenCall"
          ></v-switch>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              Incoming call options
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              Record call (4), Switch linked phone (*)
              <!-- <v-btn
                class="caption pa-0 text-capitalize"
                :ripple="false"
                plain
                text
                color="info"
                >Learn more
              </v-btn> -->
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-switch
            @change="(val) => profileUpdate('enable_call_option', val, 'Incoming call options')"
            v-model="profile.enable_call_option"
            :input-value="profile.enable_call_option"
          ></v-switch>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Show my {{ profileData && profileData.company_domain ? profileData.company_domain : 'FreeFone' }} number as caller ID when forwarding calls
            </v-list-item-title>
            <v-list-item-subtitle class="caption"
              >When you get a call through a linked number, you'll see your
              {{ profileData && profileData.company_domain ? profileData.company_domain : 'FreeFone' }} Voice number instead of the caller's number.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-switch
            @change="(val) => profileUpdate('replace_callerid', val, `Showing ${profileData && profileData.company_domain ? profileData.company_domain : 'FreeFone'} Voice number as caller ID when forwarding calls`)"
            v-model="profile.replace_callerid"
            :input-value="profile.replace_callerid"
          ></v-switch>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item :class="{ 'flex-column': $vuetify.breakpoint.xs }">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Remove call history
            </v-list-item-title>
            <v-list-item-subtitle class="caption"
              >Remove all call history from your account, including archived and
              spam content. This does not remove voicemails.<!--  Learn more -->
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-btn
            :block="$vuetify.breakpoint.xs"
            :outlined="$vuetify.breakpoint.smAndUp"
            color="primary"
            elevation="0"
            class="ms-0 ms-sm-3 pa-0 pa-sm-4 font-weight-medium"
            @click="removeCallDialog = true"
          >
            Remove
          </v-btn>
        </v-list-item>
      </v-list>

      <v-dialog v-model="removeCallDialog" width="500">
        <v-card>
          <v-card-title class="body-1">
            Permanently remove your call history?
            <v-spacer></v-spacer>
            <v-btn @click="removeCallDialog = false" small icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            Removing your call history is permanent and cannot be undone. It
            might take up to 2 days to complete this across all your devices.
            {{ profileData && profileData.company_domain ? profileData.company_domain : 'FreeFone' }} may still retain this data for billing and other purposes as
            described in the Voice terms.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              class="px-5 py-5"
              elevation="0"
              outlined
              @click="removeCallDialog = false"
              >Cancel
            </v-btn>
            <v-btn
              small
              color="danger"
              class="px-5 py-5 white--text"
              elevation="0"
              @click="deleteCallHistory"
            >
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'settingsCalls',
  props: ['profileData', 'rules'],
  data: () => {
    return {
      profile: {
        enable_web_device: false,
        enable_miss_call_email: false,
        enable_screen_call: false,
        enable_call_option: false,
        replace_callerid: false,
        call_forward_to: null,
        anon_caller_id: false
      },
      removeCallDialog: false,
      linkedNumbers: [],
      ruleCount: 0
    }
  },
  computed: {
    isCallForwardToClass () {
      return this.profile.call_forward_to?.length > 23
    }
  },
  created () {
    this.profile = this.profileData
    this.loadLinkedNumber()
    this.countRules()
  },
  methods: {
    ...mapActions([
      'editUserProfile',
      'showAlert',
      'hideAnonymousId',
      'switchScreenCall',
      'removeCallHistory',
      'getRuleList'
    ]),
    ...mapActions('numbers', ['getLinkedNumber', 'changeCallForwording']),
    async hideAnonymousIdHandler(value) {
      try {
        await this.hideAnonymousId(!!value)
        console.log(!!value, 'sssss')
        // this.showAlert({ message: 'Anonymous caller ID visibilty was changed' })
        this.profile.anon_caller_id = !!value
      } catch (err) {
        this.profile.anon_caller_id = false
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      }
    },
    async profileUpdate(key, value, message = '') {
      try {
        await this.editUserProfile({ [key]: value })
        // if (key === 'enable_web_device') return
        // if (key !== 'call_forward_to') return this.showAlert({ message: message + (value ? ' Enabled' : ' Disabled') })
        // this.showAlert({ message: 'Number updated' })
        this.$emit('reloadProfile')
      } catch (err) {
        if (key === 'call_forward_to') return this.showAlert({ error: 'There are no verified numbers for this user' })

        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    },
    async toggleScreenCall (value) {
      try {
        const data = await this.switchScreenCall(value)
        // if (!data) return
        // this.showAlert({ message: `Screen call has ${value ? 'enabled' : 'disabled'} successfully` })
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    },
    async deleteCallHistory () {
      try {
        const data = await this.removeCallHistory()

        if (!data) return
        this.showAlert({ message: 'Success' })
        this.removeCallDialog = false
        this.$emit('reloadProfile')
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    },
    async loadLinkedNumber() {
      try {
        const data = await this.getLinkedNumber()
        this.linkedNumbers = data
      } catch (err) {
        throw new Error(err)
      }
    },
    async countRules () {
      try {
        const data = await this.getRuleList()
        this.ruleCount = data.total
      } catch (err) {
        throw new Error(err)
      }
    },
    async deactivateCallForwording (uuid) {
      try {
        const data = await this.changeCallForwording({ trigger: false, uuid })
        if (!data) return
        // this.showAlert({ message: 'Call forwarding deactivated successfully' })
        this.$emit('reloadProfile')
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    },
    onlyNumbers(event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
    }
  },
  watch: {
    profileData(newVal) {
      if (newVal) {
        this.profile = this.profileData
      }
    }
  }
}
</script>

<style>
.v-btn {
  text-transform: none;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.icon-wrapper {
  background-color: rgba(127, 165, 232, 0.2);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-list-item__subtitle {
  white-space: normal !important;
}
@media (max-width: 600px) {
  .list-item-deactivate-account {
    display: block;
  }
}
</style>
