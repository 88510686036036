<template>
  <div>
    <v-row class="mt-4" style="width: 100%" v-if="$route.query.user">
      <v-col cols="12" sm="1" md="1" class="sm-margin-top-25px">
        <div>
          <v-btn
            style="height: 38px"
            color="primary"
            block
            @click="$router.back()"
            class="d-none d-md-inline-block"
            outlined
          >
            <v-icon left> mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <h1 class="mb-4 mt-4">Call Report</h1>
    <v-row class="mt-4" style="width: 100%">
      <v-col cols="12" sm="4" md="3" class="sm-margin-top-25px">
        <date-picker @changeDate="changeDate('startDate', $event)">
          <template v-slot:prepend-inner>
            <span class="w-max mr-2">From:</span>
          </template>
        </date-picker>
      </v-col>
      <v-col cols="12" sm="4" md="3" class="">
        <date-picker @changeDate="changeDate('endDate', $event)">
          <template v-slot:prepend-inner>
            <span class="w-max mr-2">To:</span>
          </template>
        </date-picker>
      </v-col>
      <v-col cols="12" sm="4" md="3" class="sm-margin-top-25px">
        <v-autocomplete
          v-model="filter.email"
          :items="itemsUsers"
          :loading="loadingUsers"
          :search-input.sync="searchUsers"
          hide-no-data
          hide-selected
          item-text="email"
          item-value="email"
          label=""
          solo
          dense
          clearable
          placeholder=""
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Email:</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2" md="2" class="sm-margin-top-25px">
        <div>
          <v-btn
            style="height: 38px"
            color="primary"
            class="text-capitalize confirm-btn"
            block
            @click="getData"
          >
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row style="margin-top: -20px;">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          item-key="id"
          :server-items-length="pagination.total"
          :options="{
             page: pagination.currentPage,
             itemsPerPage: pagination.take
          }"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100]
          }"
          @update:options="getData"
        >
          <template
            v-for="header in headers"
            v-slot:[`header.${header.value}`]
          >
            <div :key="header.value">
              <th :class="header.class">{{ header.text }}</th>
            </div>
            <div
              v-if="header.subColumns && header.subColumns.length"
              :key="header.value + 'sub'"
              class="d-grid"
              :style="`grid-template-columns: repeat(${header.subColumns.length}, 1fr)`"
            >
              <div
                v-for="subCol in header.subColumns"
                :key="subCol.value"
                :class="subCol.class"
              >
                {{ subCol.text }}
              </div>
            </div>
          </template>
          <template
            v-for="header in headers"
            v-slot:[`item.${header.value}`]="{ value, index }"
          >
            <div :key="header.value">
              <div v-if="header.hasOwnProperty('formatter')">{{ header.formatter(value) }}</div>
              <div
                v-else-if="header.subColumns && header.subColumns.length"
                class="d-grid"
                :style="`grid-template-columns: repeat(${header.subColumns.length}, 1fr)`"
              >
              <div
                v-for="subCol in header.subColumns"
                :key="subCol.value + 'row'"
                :class="subCol.cellClass"
              >
                {{ items[index][subCol.value] }}
              </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="releaseDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Release number
          <v-spacer></v-spacer>
          <v-btn @click="closeReleaseDialog" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>Are you sure you want to release number {{ releaseNum.number }}?</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="closeReleaseDialog"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="danger"
            class="px-5 py-5 white--text"
            elevation="0"
            @click="releaseNumber()"
            >Release
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import removeObjectEmptyValues from '../../utilities/removeObjectEmptyValues'
import { capitalizeFirstLetter } from '../../utilities/formatter'
import DatePicker from '@/components/DatePicker2'
import moment from 'moment'

export default {
  name: 'NumberListing',
  components: {
    DatePicker
  },
  data: () => ({
    loading: false,
    loadingUsers: false,
    pagination: {
      take: 10,
      skip: 0,
      total: 0,
      currentPage: 1
    },
    filter: {
      orderType: 'DESC',
      orderBy: 'started_on',
      type: '',
      status: '',
      email: ''
    },
    headers: [
      {
        text: 'Date',
        align: 'start',
        sortable: false,
        value: 'started_on',
        class: ['text-caption', 'greyBuoyant'],
        formatter: (x) => x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : '',
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Number Count',
        align: 'start',
        sortable: false,
        value: 'numbers',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Inbound',
        align: 'start',
        sortable: false,
        value: 'inbound',
        class: ['text-caption', 'greyBuoyant', 'text-start'],
        cellClass: 'greyBuoyant',
        subColumns: [
          {
            text: 'Non Zero Call',
            align: 'start',
            sortable: false,
            value: 'inbound_non_zero',
            class: ['text-caption', 'greyBuoyant'],
            cellClass: 'greyBuoyant'
          },
          {
            text: 'Duration',
            align: 'start',
            sortable: false,
            value: 'inbound_duration',
            class: ['text-caption', 'greyBuoyant'],
            cellClass: 'greyBuoyant'
          }
        ]
      },
      {
        text: 'Outbound',
        align: 'start',
        sortable: false,
        value: 'outbound',
        class: ['text-caption', 'greyBuoyant', 'text-start'],
        cellClass: 'greyBuoyant',
        subColumns: [
          {
            text: 'Non Zero Call',
            align: 'start',
            sortable: false,
            value: 'outbound_non_zero',
            class: ['text-caption', 'greyBuoyant'],
            cellClass: 'greyBuoyant'
          },
          {
            text: 'Duration',
            align: 'start',
            sortable: false,
            value: 'outbound_duration',
            class: ['text-caption', 'greyBuoyant'],
            cellClass: 'greyBuoyant'
          }
        ]
      }
    ],
    items: [],
    itemsUsers: [],
    searchUsers: null,
    releaseDialog: false,
    releaseNum: {}
  }),
  created () {
    const email = this.$route.query.email
    if (email) {
      this.searchUsers = atob(email)
      this.filter.email = atob(email)
      return
    }
    this.getDataUsers()
  },
  beforeDestroy() {
    clearTimeout(this.timeoutTypingIndex)
  },
  watch: {
    searchUsers () {
      if (this.loadingUsers) return
      this.getDataUsers()
    }
  },
  methods: {
    ...mapActions('admin', ['getAdminCallReports', 'getAdminUsers']),
    ...mapActions(['showAlert']),
    capitalizeFirstLetter,
    removeUrlFilter () {
      if (this.$route.query.email) {
        this.$router.replace({ name: 'AdminNumber' })
        this.getData()
      }
    },
    async getDataUsers () {
      try {
        this.loadingUsers = true
        const payload = {
          take: 200,
          email: this.searchUsers
        }
        const res = await this.getAdminUsers(payload)
        this.itemsUsers = res.data
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loadingUsers = false
      }
    },
    async getData (params = {}) {
      try {
        this.loading = true
        if (Object.keys(params).length > 0 && params.page && params.itemsPerPage) {
          this.pagination.take = params.itemsPerPage
          this.pagination.skip = (params.page - 1) * params.itemsPerPage
        }
        const payload = {
          ...removeObjectEmptyValues(this.filter),
          take: this.pagination.take,
          skip: this.pagination.skip
        }
        /* const res = await this.getAdminCallReports(payload)
        this.pagination.total = res.total
        this.items = res.data */
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loading = false
      }
    },
    openReleaseDialog (number) {
      this.releaseNum = number
      this.releaseDialog = true
    },
    closeReleaseDialog () {
      this.releaseDialog = false
      this.releaseNum = {}
    },
    async releaseNumber () {
      try {
        /* const data = await this.releaseNumberAdmin({
          userUuid: this.releaseNum.user_uuid,
          params: { did_uuid: this.releaseNum.uuid }
        })
        if (!data) return
        this.closeReleaseDialog()
        this.showAlert({ message: 'Number was released successfully' }) */
        this.getData()
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      }
    }
  }
}
</script>
<style scoped>
.mt-2px {
  margin-top: 2px;
}
.header-top {
  margin-bottom: 10px;
}
.grid-cols-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 599px) {
  .sm-margin-top-25px {
    margin-top: -25px!important;
  }
}
</style>
