<template>
  <v-container class="my-auto login-container">
    <alert-status v-if="$store.state.alertStatus !== null" />
    <template v-if="!loadingSocial">
      <v-row v-if="!error">
        <v-col cols="12" md="6" class="mx-auto d-flex flex-column align-center">
          <h4 class="display-1 text-center dark--text">Sign In</h4>
          <v-form
            ref="form"
            v-model="valid"
            class="mt-10 dark--text login-form"
            lazy-validation
          >
            <div
              v-if="errorMessage"
              class="pa-4 d-flex align-center mb-3"
              style="background-color: #EB575733;"
            >
              <v-icon size="16px" color="#EB5757" class="mr-3">mdi-alert</v-icon>
              <span>{{ errorMessage }}</span>
            </div>
            <v-layout align-center class="mb-2">
              <p class="body-1 mb-0">Email</p>
            </v-layout>
            <v-text-field
              class="loginEmail"
              background-color="#fafafa"
              v-model="form.email"
              :rules="emailRules"
              flat
              outlined
              solo
              required
              @keypress.enter.prevent="focusPassword"
            ></v-text-field>

            <v-layout align-center class="mb-2 mt-0">
              <p class="body-1 mb-0">Password</p>
              <v-btn
                class="ms-auto body-1 mb-0 pa-0 text-capitalize info--text"
                height="auto"
                text
                plain
                :ripple="false"
                @click="$router.push('reset-password')"
              >Forgot Password?
              </v-btn>
            </v-layout>
            <v-text-field
              background-color="#fafafa"
              v-model="form.password"
              outlined
              solo
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              ref="password"
              name="input-10-1"
              flat
              class="pass-input"
              :rules="passwordRules"
              @keypress.enter.prevent="loginUser"
              @click:append="show1 = !show1"
            ></v-text-field>

            <v-btn
              :disabled="!valid"
              color="primary"
              class="py-6 text-capitalize confirm-btn"
              block
              :loading="loadingLogin"
              @click="loginUser"
            >
              Login
            </v-btn>

            <p class="caption muted--text mt-6 pb-2 text-center">Or</p>

            <social-auth v-if="!isWhitelabel" />

            <p class="have-account mt-8 muted--text d-flex align-center justify-center fz-16 py-0" style="height:20px">
              Don't have an account yet?
              <v-btn
                text
                class="info--text px-1 text-capitalize py-0"
                style="font-size:16px"
                height="20px"
                plain
                :ripple="false"
                @click="$router.push('/register')"
              >
                Registration
              </v-btn>
            </p>
          </v-form>
        </v-col>
      </v-row>

      <v-row v-else class="d-flex justify-center">
        <div class="w-100 px-4">
          <v-btn
            color="primary"
            class="mb-10"
            outlined
            @click="back"
          >
            <v-icon left> mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </div>
        <div class="numberSection text-center">
          <v-img
            :src="require('../assets/png/error.png')"
            contain
            width="40"
            height="40"
            max-width="min-content"
            class="mb-6 mx-auto"
          />
          <h4 class="dark--text verifySuccess">Your account was not confirmed yet</h4>
          <div class="px-8 py-4" style="width: min(500px, 90vw);">
            <v-otp-input
              v-model="confirmCode"
              :class="{ 'error--text': confirmCodeError }"
              length="6"
              @keypress="onlyNumbers"
            />
            <p v-if="confirmCodeError" class="red--text w-100 ma-0 text-left fz-12">Incorrect verification code</p>
          </div>
          <p>You haven't received code?
            <a @click="resendCode" class="text-decoration-none info--text">Re-send code</a>
          </p>
          <span v-if="codeSent" class="ml-n5 grey--text" style="position: absolute;">Code sent to email.</span>
          <v-btn
            :disabled="confirmCode.length < 6"
            color="primary"
            class="mt-12 text-none"
            depressed
            @click="verify"
          >
            Confirm
          </v-btn>
        </div>
      </v-row>
    </template>
    <v-row v-else>
      <v-col cols="12" md="6" class="mx-auto d-flex flex-column align-center">
        <h4 class="display-1 text-center dark--text">Verification...</h4>
        <v-progress-circular indeterminate size="20" color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions } from 'vuex'
import AlertStatus from '@/components/Alert'
import SocialAuth from '@/components/SocialAuth'
import { isWhitelabel } from '@/utilities/api'

export default {
  name: 'Login',
  components: { AlertStatus, SocialAuth },
  data: () => ({
    isWhitelabel,
    error: false,
    valid: true,
    isNumberExist: false,
    // email: "",
    emailRules: [
      (v) => !!v || 'E-mail is required'
      // eslint-disable-next-line no-useless-escape
      // (v) => /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'E-mail must be valid'
    ],
    // password: "",
    passwordRules: [
      (v) => !!v || 'Password is required'
      // (v) =>
      //   (v && v.length >= 8) ||
      //   'Enter 8 to 32 characters, including 1 number, 1 lowercase letter, 1 uppercase letter and 1 special character'
    ],
    show1: false,
    show2: true,
    sipUser: {
      sip_username: '',
      password: ''
    },
    form: {
      email: '',
      password: ''
    },
    confirmCode: '',
    userUuid: '',
    codeSent: false,
    errorMessage: '',
    confirmCodeError: false,
    loadingSocial: false,
    loadingLogin: false
  }),
  created () {
    this.logout(false)

    if (this.$route.params.social) {
      this.verifySocial()
    }
  },
  methods: {
    ...mapActions([
      'login', 'getUserProfile', 'showAlert', 'setNumber', 'logout',
      'confirmEmail', 'reSendConfirmCode', 'confirmSocialCode'
    ]),
    async loginUser () {
      try {
        const isValid = this.$refs.form.validate()

        if (!isValid) return
        this.loadingLogin = true
        await this.login(this.form)
        await this.loadAndRedirect()
      } catch (err) {
        if (err.message.toString().includes('not confirmed')) {
          this.userUuid = err.user_uuid
          this.error = true
          return
        }
        this.errorMessage = isNaN(err.message) ? err.message : err.error
      } finally {
        this.loadingLogin = false
      }
    },
    async loadAndRedirect () {
      try {
        const data = await this.getUserProfile()
        this.setNumber(data.number)
        if (data.is_admin) {
          this.$router.push('/admin')
          return
        }
        if (data.number) {
          this.$router.push('/calls')
        } else {
          this.$router.push('/select-number')
        }
      } catch (err) {
        throw new Error(err.message)
      }
    },
    async resendCode () {
      try {
        if (!this.userUuid) return
        this.confirmCode = ''
        this.confirmCodeError = false
        const data = await this.reSendConfirmCode(this.userUuid)
        this.codeSent = true
        setTimeout(() => {
          this.codeSent = false
        }, 2000)
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      }
    },
    async verify () {
      try {
        const data = await this.confirmEmail(this.confirmCode)
        if (!data) return
        this.confirmCodeError = false
        this.error = false
        this.userUuid = ''
        this.loginUser()
      } catch (err) {
        this.confirmCodeError = true
        throw new Error(err)
      }
    },
    async verifySocial () {
      this.loadingSocial = true
      if (this.$route.params.social === 'apple') {
        const userData = {
          token: this.$route.query.token,
          sip_username: this.$route.query.sip_user_name,
          sip_token: this.$route.query.sip_token
        }
        localStorage.setItem('currentUser', JSON.stringify(userData))
        localStorage.setItem('sipAccess', JSON.stringify({
          sip_username: this.$route.query.sip_user_name
          // password: list.password
        }))
        await this.loadAndRedirect()
        this.loadingSocial = false
        return
      }
      try {
        const payload = {
          type: this.$route.params.social,
          code: this.$route.query.code.split('#')[0].replace('%2F', '/')
        }
        const data = await this.confirmSocialCode(payload)
        if (!data) return
        await this.loadAndRedirect()
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loadingSocial = false
      }
    },
    back () {
      this.error = false
      this.confirmCode = ''
      this.userUuid = ''
    },
    onlyNumbers (event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
    },
    focusPassword () {
      if (this.form.password) return this.loginUser()
      this.$refs.password.focus()
    }
  }
}
</script>

<style scoped lang="scss">
.loginEmail {
  height: 76px;
}
.login-form {
  width: min(560px, 90vw);
}
.error--text ::v-deep .v-input__slot fieldset {
  border-color: #ff0000;
}
.v-text-field::v-deep {
  &:not(.error--text) {
    .v-input__slot {
      height: 60px;
    }
    .v-input__slot fieldset {
      border: 1px solid #e3e3e3 !important;
    }

    &.v-input--is-dirty {
      .v-input__slot fieldset {
        border: 1px solid #27ae60 !important;
      }

      &.v-input--is-focused {
        .v-input__slot fieldset {
          border: 1px solid #5758b0 !important;
        }
      }
    }
  }
}

.pass-input::v-deep {
  .v-icon::before {
    color: #E3E3E3 !important;
  }
}
</style>
